import React from 'react';
import Measure from 'react-measure';

import {
  MeasureOuter,
  Outer,
  HeaderDiv,
  TitleOuter,
  NavigationOuter,
  IconListOuter,
  HeaderNavigationOuter,
  MenuButtonOuter,
  HeaderMobileDiv,
  MenuButtonMobileOuter,
  IconListMobileOuter,
} from './element';
import { TitleImage } from '../../atoms/titleImage';
import { IconLinkList } from '../../molecules/IconLinkList';
import { HeaderNavigation } from '../../molecules/HeaderNavigation';
import { MenuButton } from '../../atoms/MenuButton';
import { HeaderNavigationMenu } from '../../molecules/headerNavigationVertical';

import { IconLink } from '../../../domains/iconLink';
import { Link } from '../../../domains/link';
import { Image } from '../../../domains/image';

interface State {
  size: {
    width: number;
    height: number;
  };
  menusAnchorElement: null | HTMLDivElement;
}

interface Props {
  iconLinks: IconLink[];
  links: Link[];
  titleImage: Image;
  menuButtonImage: Image;
}
export class Header extends React.Component<Props, State> {
  state = {
    size: {
      width: 0,
      height: 0,
    },
    menusAnchorElement: null,
  };

  render() {
    const menusAnchorElementRef = React.createRef<HTMLDivElement>();
    return (
      <Measure
        bounds
        onResize={contentRect => {
          const size = {
            width: contentRect.bounds ? contentRect.bounds.width : 0,
            height: contentRect.bounds ? contentRect.bounds.height : 0,
          };
          this.setState({ size });
        }}
      >
        {({ measureRef }) => (
          <MeasureOuter ref={measureRef}>
            <Outer ref={menusAnchorElementRef}>
              {this.state.size.width > 980 ? (
                <HeaderDiv>
                  <TitleOuter>
                    <TitleImage {...this.props.titleImage} />
                  </TitleOuter>
                  <NavigationOuter>
                    <IconListOuter>
                      <IconLinkList iconLinks={this.props.iconLinks} />
                    </IconListOuter>
                    <HeaderNavigationOuter>
                      <HeaderNavigation links={this.props.links} />
                    </HeaderNavigationOuter>
                  </NavigationOuter>
                </HeaderDiv>
              ) : (
                <HeaderMobileDiv>
                  <MenuButtonMobileOuter>
                    <MenuButton
                      handleClick={() => this.handleClickOpenMenuButton(menusAnchorElementRef)}
                      imageUrl={this.props.menuButtonImage.imageUrl}
                    />
                  </MenuButtonMobileOuter>
                  <IconListMobileOuter>
                    <IconLinkList iconLinks={this.props.iconLinks} />
                  </IconListMobileOuter>
                  <TitleImage {...this.props.titleImage} />
                </HeaderMobileDiv>
              )}
            </Outer>
            {this.state.size.width > 980 || (
              <HeaderNavigationMenu
                links={this.props.links}
                anchorEl={this.state.menusAnchorElement}
                onClose={this.handleClickCloseMenuButton}
              />
            )}
          </MeasureOuter>
        )}
      </Measure>
    );
  }

  handleClickOpenMenuButton = (menusAnchorElementRef: any) => {
    const nextMenusAnchorElement =
      menusAnchorElementRef && menusAnchorElementRef.current ? menusAnchorElementRef.current : null;
    this.setState({ menusAnchorElement: nextMenusAnchorElement });
  };

  handleClickCloseMenuButton = () => {
    this.setState({ menusAnchorElement: null });
  };
}

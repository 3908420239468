import React, { ReactNode } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { StyledA, StyledLi } from './element';

import Menu from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

interface StyledMenuProps {
  id: string;
  keepMounted: boolean;
  open: boolean;
  onClose: (e: any) => void;
  anchorEl: null | HTMLElement;
}

const StyledMenu = withStyles({
  paper: {
    '& .MuiList-root, & .MuiMenu-list, & .MuiList-padding': {
      padding: 0,
    },
    width: '100%',
    border: '1px solid #d3d4d5',
  },
})((props: StyledMenuProps) => (
  <Menu
    elevation={0}
    autoFocus={false}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

interface HeaderNavigationMenuProps {
  anchorEl: null | HTMLElement;
  onClose: (e: any) => void;
  links: Link[];
}

interface Link {
  url: string;
  text: string;
}

export const HeaderNavigationMenu: React.FC<HeaderNavigationMenuProps> = (
  props: HeaderNavigationMenuProps
) => {
  return (
    <StyledMenu
      id="customized-menu"
      keepMounted={false}
      anchorEl={props.anchorEl}
      open={!!props.anchorEl}
      onClose={props.onClose}
    >
      {props.links.map((link, index) => (
        <StyledLi key={index}>
          <StyledA href={link.url} >
            {link.text}
          </StyledA>
        </StyledLi>
      ))}
    </StyledMenu>
  );
};

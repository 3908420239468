import React from 'react';

import { IconImage } from './element';

interface Props {
  iconUrl: string;
  innerText: string;
}

export const IconButton: React.FC<Props> = (props: Props) => {
  return (
    <IconImage iconUrl={props.iconUrl}>
      {props.innerText.length ? <span>&nbsp;({props.innerText})</span> : null}
    </IconImage>
  );
};

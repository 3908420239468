import styled from 'styled-components';

import {MOBILE_HEADER_HEIGHT} from "../../../layoutConfig";

export const ButtonsOuter = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 40px;
`;

export const Buttons = styled.div`
  display: flex;
  width: 30%;
  background-color: transparent;
  justify-content: space-around;
`;

interface ButtonProps {
  isSelected: boolean;
}
export const Button = styled.div`
  width: 10px;
  height: 10px;
  background: ${(props:ButtonProps)=>props.isSelected ? "#c1473e": "#ffffff"};
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    background: #c1473e;
  }
`;

export const Img = styled.img`
  width: 100%;
  margin: auto;
`;

interface ImgOuterProps{
    imageUrl: string;
    windowHeight: number;
}
export const ImgOuter = styled.div`
  width: 100%;
  height: ${(props:ImgOuterProps)=>props.windowHeight-MOBILE_HEADER_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-image: url(${(props:ImgOuterProps)=>props.imageUrl});
`;
export const SlideOuter = styled.div`
  margin: auto;
  width: 100%;
  font-size: 28px;
`;

export const Outer = styled.div`
  position: relative;
`;

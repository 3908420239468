import React, { Component } from 'react';
import './App.css';
import { Header } from './presentations/organisms/header';

import { IconLink } from './domains/iconLink';
import { Link } from './domains/link';
import { Image } from './domains/image';
import { Slider } from './presentations/organisms/slider';

interface Props {
  iconLinks: IconLink[];
  links: Link[];
  titleImage: Image;
  menuButtonImage: Image;
  isTest?: boolean;
}


class App extends Component<Props, {}> {
  render() {
    return (
      <>
        <Header {...this.props} />
        <Slider />
        {this.props.isTest && (
          <>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
            <p>動作テスト用文字列</p>
          </>
        )}
      </>
    );
  }
}

export default App;

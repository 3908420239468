import React from 'react';

import { Outer, Image } from './element';

interface Props {
  imageUrl: string;
  handleClick: ()=>void;
}

export const MenuButton: React.FC<Props> = (props: Props) => {
  return (
    <Outer onClick={props.handleClick} >
      <Image src={props.imageUrl} />
    </Outer>
  );
};

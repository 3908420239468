import React from 'react';

import { IconListUl, IconListItem, IconLinkOuter} from './element';
import { IconButton } from '../../atoms/iconLink/index';
import {IconLink} from "../../../domains/iconLink";

interface Props {
  iconLinks: IconLink[];
}


export const IconLinkList: React.FC<Props> = (props: Props) => {
  return (
    <IconListUl>
      {props.iconLinks.map((iconLink, index) => (
        <IconListItem key={index}>
          <IconLinkOuter href={iconLink.linkUrl}>
          <IconButton {...iconLink} />
          </IconLinkOuter>
        </IconListItem>
      ))}
    </IconListUl>
  );
};

import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';


export const TextLinkUl = styled.ul`
    display: block;
    list-style-type: none;
    height:20px;
    padding: 0;
    color: #555555;
    font-size: 16px;
    line-height: 1.5;
`;


export const TextLinkItem= styled.li`
    position: relative;
    display: inline;
    padding: 0 15px;
    vertical-align: top;
`;
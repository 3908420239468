import React, { Component } from 'react';
import { default as SlickSlider } from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { Button, Buttons, ButtonsOuter, Img, ImgOuter, SlideOuter, Outer } from './element';

export interface Props {
  images: string[];
  setting?: any;
  windowSize: {
    width: number;
    height: number;
  };
  slideIndex: number;
  handleChangeSlideIndex: (slideIndex: number)=>void;
}

export class DesktopSlider extends Component<Props, {}> {
  private slider!: SlickSlider;

  render() {
    const settings = this.props.setting
      ? this.props.setting
      : {
          dots: false,
          infinite: true,
          arrows: false,
          speed: 800,
          fade: true,
          touchThreshold: 100,
          centerMode: true,
          centerPadding: '0%',
          autoplay: true,
          autoplaySpeed: 4000,
          initialSlide: this.props.slideIndex,
          beforeChange: (current: number, next: number) => this.props.handleChangeSlideIndex(next),
        };

    return (
      <Outer>
        <SlideOuter>
          <SlickSlider ref={(component: SlickSlider) => (this.slider = component)} {...settings}>
            {this.props.images.map((content, index) => {
              return <ImgOuter key={index} imageUrl={content} windowWidth={this.props.windowSize.width||80}/>;
            })}
          </SlickSlider>
        </SlideOuter>
        <ButtonsOuter>
          <Buttons>
            {this.props.images.map((_, index: number) => {
              return (
                <Button
                  key={index}
                  isSelected={this.props.slideIndex === index}
                  onClick={() => {
                    this.slider.slickGoTo(index);
                  }}
                />
              );
            })}
          </Buttons>
        </ButtonsOuter>
      </Outer>
    );
  }
}

import React from 'react';
import { TestDiv } from './element';
import { MobileSlider } from '../../molecules/MobileSlider';
import { DesktopSlider } from '../../molecules/DesktopSlider';

interface State {
  windowSize: {
    width: number;
    height: number;
  };
  slideIndex: number;
}
export class Slider extends React.Component<{}, State> {
  state = {
    windowSize: {
      width: 0,
      height: 0,
    },
    slideIndex: 0,
  };
  componentDidMount() {
    const size = this.getWindowSize();
    this.setState({ windowSize: size });
    window.addEventListener('resize', () => {
      const size = this.getWindowSize();
      this.setState({ windowSize: size });
    });
  }

  render() {
    return this.state.windowSize.width > 980 || this.state.windowSize.width >= this.state.windowSize.height ? (
        <DesktopSlider
          {...{
            ...this.state,
            handleChangeSlideIndex: this.handleChangeSlideIndex,
            images: [
              'https://file003.shop-pro.jp/PA01441/123/slider_images/desktop/slider_pc_1.jpg',
              'https://file003.shop-pro.jp/PA01441/123/slider_images/desktop/slider_pc_2.jpg',
              'https://file003.shop-pro.jp/PA01441/123/slider_images/desktop/slider_pc_3.jpg',
            ],
          }}
        />
    ) : (
      <MobileSlider
        {...{
          ...this.state,
          handleChangeSlideIndex: this.handleChangeSlideIndex,
          images: [
            'https://file003.shop-pro.jp/PA01441/123/slider_images/mobile/slider_sp_1.jpg',
            'https://file003.shop-pro.jp/PA01441/123/slider_images/mobile/slider_sp_2.jpg',
            'https://file003.shop-pro.jp/PA01441/123/slider_images/mobile/slider_sp_3.jpg',
          ],
        }}
      />
    );
  }

  getWindowSize = () => {
    const e = document.documentElement;
    const g = e.getElementsByTagName('body')[0];
    const w = window.innerWidth || e.clientWidth || g.clientWidth;
    const h = window.innerHeight || e.clientHeight || g.clientHeight;

    return {
      width: w,
      height: h,
    };
  };

  handleChangeSlideIndex = (slideIndex: number) => {
    this.setState({ slideIndex: slideIndex });
  };
}

import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';

export const Outer = styled.h1`
  margin: 0;
`;

const customMedia = generateMedia({
  desktop: '980px',
});

interface Props {
  iconUrl: string;
}

export const IconImage = styled.a`
    display: inline-block;
    color: #555555;
    text-decoration: none;
    font-weight: normal;
    font-size: 13px;
    padding-left: 0px;
    cursor: pointer;
    margin: 0;
  &::before {
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    font-size: 13px;
    content: '';
    background-position: bottom;
    background-image: url(${(props: Props) => props.iconUrl});
  }
`;

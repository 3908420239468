import React from 'react';

import { Outer, ResponsiveTitleImage } from './element';

interface Props {
  imageUrl: string;
  alt?: string;
}

export const TitleImage: React.FC<Props> = (props: Props) => {
  return (
    <Outer>
      <ResponsiveTitleImage src={props.imageUrl} alt={props.alt || ""} />
    </Outer>
  );
};

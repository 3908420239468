import styled from 'styled-components';


export const Outer = styled.div`
  width: 30px;
  height: 70px;
  cursor: pointer;
`;

export const Image = styled.img`
  max-width: 100%;
  width: auto;
  height: auto;
  border: 0;
  vertical-align: middle;
`;

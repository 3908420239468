import styled from 'styled-components';

export const StyledA = styled.a`
  display: inline-block;
  color: #555555;
  text-decoration: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: 5px;
  &:hover {
    color: #c1473e;
    outline: 0;
  }
`;

import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';

export const Outer = styled.h1`
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
    height:100%;
    width: 100%;
`;

const customMedia = generateMedia({
  desktop: '980px',
});

export const ResponsiveTitleImage = styled.img`
  // デスクトップの時
  max-width: 120px;
  
  // タブレット以下の時
  ${customMedia.lessThan('desktop')`
     max-width: 70px;
  `};
  height: auto;
`;

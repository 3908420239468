import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import {getIconLinksSample} from "./domains/iconLink/sample";
import {getLinksSample} from "./domains/link/sample";
import {getMenuButtonImageSample, getTitleImageSample} from "./domains/image/sample";

const iconLinks = getIconLinksSample();
const links = getLinksSample();
const titleImage = getTitleImageSample();
const menuButtonImage = getMenuButtonImageSample();

ReactDOM.render(<App
    iconLinks={iconLinks}
    links={links}
    titleImage={titleImage}
    menuButtonImage={menuButtonImage}
    isTest={true}
/>, document.getElementById('root'));

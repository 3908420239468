import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';

export const Outer = styled.h1`
  margin: 0;
`;

export const IconListUl = styled.ul`
  display: inline-block;
  list-style-type: none;
  margin: 0;
  height: 20px;
  padding: 0;
`;

export const IconListItem = styled.li`
  display: inline-block;
  vertical-align: top;
  margin: 0 10px;
  height: 20px;
  font-size: 13px;
  padding: 0;
`;

export const IconLinkOuter = styled.a`
  width: 100%;
  height: 100%;
`;

import styled from 'styled-components';

import {MOBILE_HEADER_HEIGHT} from "../../../layoutConfig"

export const MeasureOuter = styled.div`
  width: 100%;
`;

export const Outer = styled.div`
  margin: 0 auto;
  width: 96%;
  padding-top: 10px;
`;

export const HeaderDiv = styled.div`
  width: 100%;
  display: flex;
`;

export const TitleOuter = styled.div`
  flex-grow: 0;
`;

export const NavigationOuter = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const IconListOuter = styled.div`
  margin: 0px 1% 0px auto;
`;

export const HeaderNavigationOuter = styled.div`
  margin: 25px 0px 10px auto;
`;

export const MenuButtonOuter = styled.div`
  margin: 0px 0px 0px auto;
  height: 60px;
`;

export const HeaderMobileDiv = styled.div`
  width: 100%;
  height: ${MOBILE_HEADER_HEIGHT}px;
  text-align: center;
  position: relative;
`;

export const MenuButtonMobileOuter = styled.div`
  position: absolute;
  top: 5px;
  left: 10px;
`;

export const IconListMobileOuter = styled.div`
  position: absolute;
  top: 18px;
  right: 0;
`;

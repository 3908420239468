import { IconLink } from './index';

export const getIconLinksSample = (): IconLink[] => {
  return [
    {
      iconUrl: 'https://img21.shop-pro.jp/PA01437/804/etc/account1022.png',
      innerText: '',
      linkUrl: '#',
    },
    {
      iconUrl: 'https://img21.shop-pro.jp/PA01437/804/etc/cart1022.png',
      innerText: '2',
      linkUrl: '#',
    },
  ];
};

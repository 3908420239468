import styled from 'styled-components';

export const StyledA = styled.a`
  display: block;
  text-align: center;
  color: #555555;
  text-decoration: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  padding: 20px 0px;
  &:hover {
    background: #f9f9f9;
    color: #c1473e;
    outline: 0;
  }
`;

export const StyledLi = styled.li`
  display: inline-block;
  width: 100%;
  border-bottom: solid #dbdbdb 1px;
   &:last-child {
        border-bottom: none;
    }
`;

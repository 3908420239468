import { Link } from './index';

export const getLinksSample = (): Link[] => {
  return [
    {
      url: '#',
      text: 'ハイランズコーヒーとは',
    },
    {
      url: '#',
      text: '商品',
    },
    {
      url: '#',
      text: 'レシピ',
    },
    {
      url: '#',
      text: '読みもの',
    },
    {
      url: '#',
      text: 'ご利用ガイド',
    },
    {
      url: '#',
      text: 'お問い合わせ',
    },
  ];
};

import { Image } from './index';

export const getTitleImageSample = (): Image => {
  return {
    imageUrl: 'https://img21.shop-pro.jp/PA01437/804/PA01437804.png?cmsp_timestamp=20191111142902',
    alt: '【正規輸入販売】ハイランズコーヒー（HIGHLANDS COFFEE）',
  };
};

export const getMenuButtonImageSample = (): Image => {
  return {
    imageUrl: 'https://img.shop-pro.jp/tmpl_img/68/header_menu_btn.png',
  };
};

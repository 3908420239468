import React from 'react';
import { StyledA } from './element';

interface Props {
    url: string;
    text: string;
}

export const TextLink: React.FC<Props> = (props: Props) => {
    return (
        <StyledA href={props.url}>
            {props.text}
        </StyledA>
    );
};

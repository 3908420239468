import React from 'react';

import { TextLinkUl, TextLinkItem } from './element';
import { TextLink } from '../../atoms/TextLink';

interface Props {
  links: Link[];
}

interface Link {
  url: string;
  text: string;
}

export const HeaderNavigation: React.FC<Props> = (props: Props) => {
  return (
    <TextLinkUl>
      {props.links.map((link, index) => (
        <TextLinkItem key={index}>
          <TextLink {...link} />
        </TextLinkItem>
      ))}
    </TextLinkUl>
  );
};

import React, { Component } from 'react';
import { default as SlickSlider } from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { Button, Buttons, ButtonsOuter, Img, ImgOuter, SlideOuter, Outer } from './element';

export interface Props {
  images: string[];
  setting?: any;
  slideIndex: number;
  handleChangeSlideIndex: (slideIndex: number) => void;
}

export interface State {
  height: number;
}

export class MobileSlider extends Component<Props, State> {
  private slider!: SlickSlider;

  constructor(props: Props) {
    super(props);
    // ここで this.setState() を呼び出さないでください
    const height = this.getWindowSize().height;
    this.state = { height: height };
  }

  render() {
    const settings = this.props.setting
      ? this.props.setting
      : {
          dots: false,
          infinite: true,
          arrows: false,
          speed: 800,
          fade: true,
          touchThreshold: 100,
          centerMode: true,
          centerPadding: '0%',
          autoplay: true,
          autoplaySpeed: 4000,
          initialSlide: this.props.slideIndex,
          beforeChange: (current: number, next: number) => this.props.handleChangeSlideIndex(next),
        };

    return (
      <Outer>
        <SlideOuter>
          <SlickSlider ref={(component: SlickSlider) => (this.slider = component)} {...settings}>
            {this.props.images.map((content, index) => {
              return (
                <ImgOuter key={index} imageUrl={content} windowHeight={this.state.height || 80} />
              );
            })}
          </SlickSlider>
        </SlideOuter>
        <ButtonsOuter>
          <Buttons>
            {this.props.images.map((_, index: number) => {
              return (
                <Button
                  key={index}
                  isSelected={this.props.slideIndex === index}
                  onClick={() => {
                    this.slider.slickGoTo(index);
                  }}
                />
              );
            })}
          </Buttons>
        </ButtonsOuter>
      </Outer>
    );
  }
  getWindowSize = () => {
    const e = document.documentElement;
    const g = e.getElementsByTagName('body')[0];
    const w = window.innerWidth || e.clientWidth || g.clientWidth;
    const h = window.innerHeight || e.clientHeight || g.clientHeight;

    return {
      width: w,
      height: h,
    };
  };
}
